import { WorkspaceFeatureOptionTypeEnum } from '@/api/openapi';
import { COMPANY_ROUTES } from '@/router';
import { useAreaOptions } from '@/store/areaOptions';
import { useUserInfoStore } from '@/store/userInfo';
import { format } from 'date-fns';
import { RouteLocationNormalizedLoaded, Router } from 'vue-router';
interface Cmsis {
  breadcrumbs: {
    parents: Array<{ name: string; label: string }>;
    current: { label: string };
  };
}

const BREADCRUMBS = {
  parents: [
    { name: COMPANY_ROUTES.top, label: 'ホーム' },
    { name: '', label: 'CMセールスインパクト' }
  ],
  current: { label: '売上効果シミュレーター' }
};

export const useCmsis = (): Cmsis => {
  return {
    breadcrumbs: BREADCRUMBS
  };
};

export const CHART_OPTIONS_BASE = {
  chart: {
    height: 320,
    animation: true,
    spacingRight: 20,
    marginTop: 28
  },
  series: {
    borderWidth: 0
  },
  yAxis: {
    gridLineColor: '#e6e6e6',
    gridLineWidth: 0.5,
    title: {
      reserveSpace: false,
      align: 'high',
      offset: 0,
      rotation: 0,
      y: -15,
      x: 1,
      style: {
        'text-anchor': 'start',
        fontSize: 10
      }
    }
  },
  xAxis: {
    title: {
      // reserveSpace: true,
      align: 'high',
      // offset: 10,
      // rotation: 0,
      y: -17,
      x: 20,
      style: {
        'text-anchor': 'end',
        fontSize: 10
      }
    }
  },
  legend: {
    enabled: true,
    itemStyle: {
      fontSize: 12,
      color: '#425252',
      fontWeight: 'normal'
    }
  },
  subtitle: {
    align: 'right',
    y: 5,
    style: {
      // 'text-anchor': 'start',
      fontSize: 10
    }
  },
  plotOptions: {
    series: {
      pointWidth: 20,
      marker: {
        symbol: 'circle',
        enabled: false,
        states: {
          hover: {
            enabled: false
          }
        }
      }
    }
  },
  title: false,
  credits: {
    enabled: false
  },
  exporting: {
    enabled: true,
    buttons: false,
    allowHTML: true,
    chartOptions: {
      chart: {
        marginTop: 50,
        spacingLeft: 15
      },
      title: {
        style: {
          fontSize: '12px'
        }
      },
      credits: {
        enabled: true,
        text: '© TVAL Switch Media, Inc.'
      },
      xAxis: {
        max: null
      }
    }
  }
};

export const exportFileName = (
  v: string,
  startDate: string,
  endDate: string,
  projectId: string
): string => {
  if (startDate) {
    const start = format(new Date(startDate), 'yyyyMMdd');
    const end = format(new Date(endDate), 'yyyyMMdd');
    return `${v}_${projectId}_${start}-${end}`;
  }
  return '';
};

export const checkAuthAccess = async (
  route: RouteLocationNormalizedLoaded
): Promise<boolean> => {
  const userInfoStore = useUserInfoStore();
  if (userInfoStore.joinableCompanies.length === 0) {
    await userInfoStore.fetchJoinableCompanies();
  }
  const notAccessMenu = type => {
    const status = userInfoStore.getFeatureOptionStatus(route, type);
    return !status;
  };
  const workspace = userInfoStore.currentWorkspaceFromRoute(route);
  const areaInfoStore = useAreaOptions();
  if (areaInfoStore.selectOptions.length === 0) {
    await areaInfoStore.fetch(route, undefined, undefined, false);
  }
  const CmSalesImpactOption = workspace?.featureOptions.find(
    v => v.type === WorkspaceFeatureOptionTypeEnum.CmSalesImpact
  );
  const CmSalesImpactSimulatorOption = workspace?.featureOptions.find(
    v => v.type === WorkspaceFeatureOptionTypeEnum.CmSalesImpactSimulator
  );
  const status =
    // ワークスペースのCmSalesImpactオプションが契約中であること
    !notAccessMenu(WorkspaceFeatureOptionTypeEnum.CmSalesImpact) &&
    // カンパニーのCmSalesImpactオプションが契約中であること
    CmSalesImpactOption?.companyIds?.includes(
      Number(route.params.companyId as string)
    ) &&
    // ワークスペースのCmSalesImpactSimulatorオプションが契約中であること
    !notAccessMenu(WorkspaceFeatureOptionTypeEnum.CmSalesImpactSimulator) &&
    // カンパニーのCmSalesImpactSimulatorオプションが契約中であること
    CmSalesImpactSimulatorOption?.companyIds?.includes(
      Number(route.params.companyId as string)
    );

  return process.env.NODE_ENV !== 'local' && !status ? false : true;
};

export const gotoTop = async (
  companyId: number,
  router: Router
): Promise<void> => {
  await router.push({
    name: COMPANY_ROUTES.top,
    params: { companyId }
  });
};

import { ref, Ref, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { COMPANY_ROUTES, CMSIS_ROUTES } from '@/router';
import { CmsisApi } from '@/api';
import {
  CmSalesImpactSimulatorResult,
  CmSalesImpactSimulatorResultCmsisSimulationStatusEnum,
  CmSalesImpactSimulatorResultSimulationResult
} from '@/api/openapi';
import { Project } from '@/composables/cmsis/store';

import useLoading from '@/composables/loading';
import { toast } from '@/components/ui/Toast';

const BREADCRUMBS = {
  parents: [
    { name: COMPANY_ROUTES.top, label: 'ホーム' },
    {
      name: '',
      label: 'CMセールスインパクト'
    },
    {
      name: CMSIS_ROUTES.index,
      label: '売上効果シミュレーター'
    }
  ],
  current: { label: '結果' }
};
interface Result {
  isLoading: Ref<boolean>;
  data: Ref<CmSalesImpactSimulatorResultSimulationResult[]>;
  pageTitle: Ref<string>;
  status: Ref<CmSalesImpactSimulatorResultCmsisSimulationStatusEnum>;
  breadcrumbs: Ref<{
    parents: Array<{ name: string; label: string }>;
    current: { label: string };
  }>;
  // eslint-disable-next-line
  papa: any;
}

interface DataTable {
  rowClass;
}

export const HEADERS: {
  id: string;
  label: string;
  class: string;
  sortable?: boolean;
  unit?: string;
}[] = [
  // { id: 'isIcon', label: '', width: '12px' },
  {
    id: 'numberOfCampaignWeeks',
    label: 'キャンペーン週数',
    class: 'p-sortable-column--md',
    sortable: true
  },
  {
    id: 'roas',
    label: 'ROAS',
    class: 'p-sortable-column--md',
    sortable: true,
    unit: '%'
  },
  {
    id: 'totalGrp',
    label: 'GRP',
    class: 'p-sortable-column--md'
  },
  {
    id: 'cmSalesEffect',
    label: '①購入増加額',
    class: 'p-sortable-column--md',
    sortable: true,
    unit: '円'
  },
  {
    id: 'totalCost',
    label: '②出稿費用',
    class: 'p-sortable-column--md',
    unit: '円'
  },
  {
    id: 'cmSalesEffectMinusTotalCost',
    label: '①−②',
    class: 'p-sortable-column--md',
    sortable: true,
    unit: '円'
  }
];

export const COLORS = [
  '#6b90df',
  '#99d77b',
  '#fad379',
  '#f39cbc',
  '#65aec1',
  '#d5e989',
  '#f3ac82',
  '#8b82b9',
  '#8e95a2'
];

export const useResult = (project: Project): Result => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const papa: any = inject('$papa');

  const route = useRoute();

  const router = useRouter();
  const { params } = route;
  const pageTitle = ref('');
  const cmsisProjectId = Number(params.projectId);
  const breadcrumbs = ref(BREADCRUMBS);
  const data = ref([] as CmSalesImpactSimulatorResultSimulationResult[]);
  const status = ref(
    '' as CmSalesImpactSimulatorResultCmsisSimulationStatusEnum
  );

  (async () => {
    // タイトルの書き換え
    if (project.productName) {
      const name = project.productName;
      const { title } = document;
      document.title = name + ' | ' + title;
      // パンくずの書き換え
      // breadcrumbs.value.current.label = name;
      // ページタイトル
      pageTitle.value = project.cmsisProjectTitle;
    }
  })();

  // 最新のレポート詳細を取得
  const [isLoading, fetch] = useLoading(_fetch);
  (async () => {
    const { cmsisSimulationStatus, simulationResult } = await fetch({
      cmsisProjectId
    });
    // 集計中は一覧に移動
    if (
      cmsisSimulationStatus ===
        CmSalesImpactSimulatorResultCmsisSimulationStatusEnum.Pending ||
      cmsisSimulationStatus ===
        CmSalesImpactSimulatorResultCmsisSimulationStatusEnum.InProgress
    ) {
      await router.push({
        name: CMSIS_ROUTES.index
      });
      toast({
        title: 'シミュレーション集計中のため一覧に移動しました。',
        variant: 'error'
      });
    } else {
      status.value = cmsisSimulationStatus;
      data.value = simulationResult;
    }
  })();

  return {
    breadcrumbs,
    pageTitle,
    isLoading,
    data,
    status,
    papa
  };
};

export const _fetch = async ({
  cmsisProjectId
}: {
  cmsisProjectId: number;
}): Promise<CmSalesImpactSimulatorResult> => {
  // シミュレーション結果を取得
  try {
    const {
      data
    } = await CmsisApi.getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdResult(
      cmsisProjectId
    );
    return data;
  } catch (e) {
    toast({
      title: 'シミュレーション結果を取得失敗',
      variant: 'error'
    });
    return {} as CmSalesImpactSimulatorResult;
  }
};

export const useDataTable = (
  data: CmSalesImpactSimulatorResultSimulationResult[]
): DataTable => {
  // 週ごとに丸める
  const weeks: {
    [key: number]: CmSalesImpactSimulatorResultSimulationResult[];
  } = data.reduce((group, p) => {
    group[p.numberOfCampaignWeeks] = group[p.numberOfCampaignWeeks] ?? [];
    group[p.numberOfCampaignWeeks].push(p);
    return group;
  }, {});
  // maxRoas
  const maxRoas = Object.values(weeks).map(v =>
    v.reduce((p, c) => {
      // roasが同値なら増加額が高い方
      if (p && p.roas == c.roas) {
        return p.cmSalesEffect > c.cmSalesEffect ? p : c;
      }
      // Roasを比べる
      return p &&
        p.roas !== undefined &&
        c.roas !== undefined &&
        p.roas > c.roas
        ? p
        : c;
    })
  );

  const rowClass = data => {
    return [{ bg: maxRoas.includes(data) }, `g${maxRoas.indexOf(data) + 1}`];
  };
  return {
    rowClass
  };
};

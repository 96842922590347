interface ConditionsHeaders {
  label: string;
  id: string;
}

export const CONDITIONS_HEADERS: ConditionsHeaders[] = [
  {
    label: '商品／ブランド',
    id: 'productName'
  },
  {
    label: '参照期間',
    id: 'duration'
  },
  {
    label: 'リーチ基準',
    id: 'reachBasic'
  },
  {
    label: 'エリア',
    id: 'areaName'
  },
  {
    label: 'パーコスト',
    id: 'perCost'
  },
  {
    label: '絵柄',
    id: 'stationPlacementPatterns'
  },
  {
    label: 'キャンペーン週数',
    id: 'campaignWeeks'
  },
  {
    label: 'GRP',
    id: 'grpTick'
  }
  // {
  //   label: '区分数',
  //   id: 'numOfDivision'
  // }
];

import {
  StationPlacementPatternPlacementPatternEnum,
  StationPlacementPattern
} from '@/api/openapi';

export interface SelectType {
  value: StationPlacementPatternPlacementPatternEnum | 'CUSTOM';
  label: string;
  icon?: string;
}

export const SELECT_OPTIONS: SelectType[] = [
  {
    value: StationPlacementPatternPlacementPatternEnum.All,
    label: '全日',
    icon: 'rectangle'
  },
  {
    value: StationPlacementPatternPlacementPatternEnum.Yo,
    label: 'ヨの字',
    icon: 'box-drawing-yo'
  },
  {
    value: StationPlacementPatternPlacementPatternEnum.Ko,
    label: 'コの字',
    icon: 'box-drawing-ko'
  },
  {
    value: StationPlacementPatternPlacementPatternEnum.Rl,
    label: '逆L',
    icon: 'box-drawing-reverse-l'
  },
  {
    value: StationPlacementPatternPlacementPatternEnum.Ichi,
    label: '一の字',
    icon: 'box-drawing-ichi'
  }
];

export const getPlacementPatternByCode = (
  code: StationPlacementPatternPlacementPatternEnum
): SelectType => {
  return SELECT_OPTIONS.filter(i => i.value === code)[0];
};

export const formatStationLabel = (key: string): string => {
  switch (key) {
    case 'NTV':
      return '日本テレビ';
    case 'EX':
      return 'テレビ朝日';
    case 'TX':
      return 'テレビ東京';
    case 'CX':
      return 'フジテレビ';
    default:
      return key;
  }
};

export const stationFormat = (
  stationPlacementPatterns: StationPlacementPattern[]
): string => {
  const orig = stationPlacementPatterns;
  const isMatch = orig.every(
    v => v.placementPattern === orig[0].placementPattern
  );
  if (isMatch) {
    return `全局: ${getPlacementPatternByCode(orig[0].placementPattern).label}`;
  }
  const res = orig.map(
    v =>
      `${formatStationLabel(v.stationCode)}系列: ${
        getPlacementPatternByCode(v.placementPattern).label
      }`
  );
  return res.join(', ');
};

import {
  CmSalesImpactSimulatorCondition,
  CmSalesImpactSimulatorResultSimulationResult
} from '@/api/openapi';
import { Project } from '@/composables/cmsis/store';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { roundNumber } from '@/common/formatter';
import { HEADERS } from './result';
import { stationFormat } from '@/composables/salesImpact/PlacementPattern';
// CSV
const createSampleConditionTexts = (
  title: string,
  conditions: CmSalesImpactSimulatorCondition
): string[][] => {
  const sampleCondition: string[][] = [];

  const formattedPeriods = conditions.periods.map(({ startDate, endDate }) => {
    const formattedStartDate = format(
      new Date(startDate),
      'yyyy年MM月dd日(E)',
      { locale: ja }
    );
    const formattedEndDate = format(new Date(endDate), 'yyyy年MM月dd日(E)', {
      locale: ja
    });
    return `${formattedStartDate}~${formattedEndDate}`;
  });

  sampleCondition.push(['TVAL - CMセールスインパクト 売上効果シミュレーター']);
  sampleCondition.push(['商品／ブランド名:', `${conditions.productName}`]);
  sampleCondition.push(['参照期間:', `${formattedPeriods.join(', ')}`]);
  sampleCondition.push([
    'リーチ基準:',
    `${conditions.reachWeeks}週間で${conditions.reachFrequency}回以上視聴`
  ]);
  sampleCondition.push(['エリア:', conditions.areaName]);
  sampleCondition.push([
    '絵柄:',
    stationFormat(conditions.stationPlacementPatterns)
  ]);
  sampleCondition.push([
    'レポート作成日時:',
    format(new Date(conditions.lastUpdateDateTime), 'yyyy年M月d日(E) HH:mm', {
      locale: ja
    })
  ]);
  sampleCondition.push(['データ提供元:', 'Switch Media, Inc.']);
  return sampleCondition;
};

const outputCsv = (
  filename: string,
  sampleCondition: string[][],
  header: string[],
  body: string[][],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  $papa: any
): void => {
  const csvData: string[][] = [];
  csvData.push(...sampleCondition);
  csvData.push([]);
  csvData.push(header);
  csvData.push(...body);

  const link = document.createElement('a');
  const blob = new Blob(
    [
      new Uint8Array([0xef, 0xbb, 0xbf]),
      $papa.unparse({
        data: csvData
      })
    ],
    {
      type: 'text/csv'
    }
  );

  link.setAttribute('download', filename);
  link.setAttribute('href', window.webkitURL.createObjectURL(blob));
  link.click();
};

export const outputCmsisCsv = (
  filename: string,
  title: string,
  graphData: CmSalesImpactSimulatorResultSimulationResult[],
  conditions: Project,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  $papa: any
): void => {
  const sampleConditionTexts = createSampleConditionTexts(title, conditions);

  // headers
  const tableHeader: string[] = HEADERS.map(v =>
    v.unit ? `${v.label}(${v.unit})` : v.label
  );
  // body
  const tableBody = graphData.map(v => [
    `${v.numberOfCampaignWeeks}週`,
    v.roas === undefined
      ? '-'
      : v.roas === 0.0
      ? '0'
      : roundNumber(v.roas, 7, false),
    v.totalGrp === 0
      ? roundNumber(0, 7, false)
      : roundNumber(v.totalGrp, 7, false),
    roundNumber(v.cmSalesEffect, 1, false),
    roundNumber(v.totalCost, 1, false),
    roundNumber(v.cmSalesEffect - v.totalCost, 1, false)
  ]);
  outputCsv(filename, sampleConditionTexts, tableHeader, tableBody, $papa);
};
